<template>
  <v-card 
    class="mx-auto"
  > 
    
    <v-dialog
      v-model="showAssignLicenseAllocation"
      max-width="90%"
      v-if="this.selectedLicense"
    >
      <videon-card heading="License Allocations" showClose="true" @close="showAssignLicenseAllocation = false">
        <v-card-text>
          <license-allocations :license="this.selectedLicense" @close="loadLicenses(0)" />
        </v-card-text>
      </videon-card>
    </v-dialog>
    
    
    <v-toolbar flat>
      <v-toolbar-title>
        <v-avatar
          height="32px"
          class="mx-auto"
        >
          <videon-logo />
        </v-avatar>
        License Manager
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    
    <v-divider/>
    
    <v-card-text>
      <v-card flat>
        <v-card-title>
          Licenses assigned to {{ orgName }}
          <v-spacer/>
        </v-card-title>
        
        <v-card-text>
          <v-alert dense outlined type="error" v-if="error" >
            {{ errorText }}
          </v-alert>
          
          <v-row>
            <v-col cols="12">
              <v-data-table
                :headers="headers"
                :items="items"
                :loading="loading"
                :page.sync="page"
                :items-per-page="itemsPerPage"
                hide-default-footer
              >
                <template v-slot:item.profile_guid="{ item }">
                  <license-label :license="item"></license-label>
                </template>
                <template v-slot:item.unlimited="{ item }">
                  {{ licenseTypeLabel(item) }}
                </template>
                <template v-slot:item.profile_platform="{ item }">
                  {{ $helpers.getLabel(item.profile_platform) }}
                </template>
                <template v-slot:item.created="{ item }">
                  {{ item.created | moment("YYYY/MM/DD") }}
                </template>
                <template v-slot:item.expires="{ item }">
                  <span v-if="item.expires" :class="hasExpired(item) ? 'red--text' : ''">
                    {{ item.expires | moment("YYYY/MM/DD") }}
                  </span>
                  <span v-else>Never</span>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-btn
                    class="ma-1"
                    x-small
                    outlined
                    color="primary"
                    @click="assignLicenses(item)"
                    v-if="canEdit && item.allocation_limit && item.allocation_limit > 0"
                  >
                    Manage Allocation
                  </v-btn>
                </template>
              </v-data-table>
              <data-table-pagination v-model="page" :paginationTokens="paginationTokens" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script> 
  import { mapGetters } from 'vuex'
  import LicenseAllocations from './LicenseAllocations.vue'
  
  import moment from 'moment'
  
  export default {
    name: 'OrgLicenseManager',
    components: {
      LicenseAllocations,
    },
    data() {
      return { 
        error: false,
        errorText: '',
        
        loading: false,
        
        showAssignLicenseAllocation: false,
        selectedLicense: false,
        
        headers: [
          { text: 'License Name', align: '', value: 'profile_guid', sortable: false },
          { text: 'License Type', align: '', value: 'unlimited', sortable: false },
          { text: 'License Platform', align: '', value: 'profile_platform', sortable: false },
          { text: 'Issue Date', align: 'center', value: 'created', sortable: false },
          { text: 'Expiration Date', align: 'center', value: 'expires', sortable: false },
          { text: '', align: 'end', value: 'actions', sortable: false }
        ],
        
        items: [],
        
        itemsPerPage: 50,
        page: 1,
        pageCount: 0,
      
        paginationTokens: []
      }
    },
    mounted() {
      this.loadLicenses()
    },
    watch: {
      page() {
        this.loadLicenses(this.page - 1)
      },
      itemsPerPage() {
        this.items = []
        this.page = 1
        this.loadLicenses(this.page - 1)
      },
    },
    computed: {
      ...mapGetters('user', ['organization']),
      
      orgName() {
        if (this.organization.org_name) {
          return this.organization.org_name
        }
        return 'Unknown Org'
      },
      canEdit() {
        if (this.organization) {
          return this.$organizations.getOrg(this.organization.org_guid).canEdit()
        }
        return false
      }
    },
    methods: {
      licenseTypeLabel(item) {
        if (item.unlimited) {
          return 'Unlimited License'
        } else if (item.allocation_limit) {
          return 'Per-Device License (' + item.allocation_limit + ' allocated, ' + item.allocated + ' in use)'
        } else if (item.serial_number) {
          return 'Base License'
        }
        return 'Unknown'
      },
      
      entitlementProfileName(item) {
        if (item.profile_guid) {
           return this.$entitlementProfiles.getEntitlementProfile(item.profile_guid).displayName()
        }
        return 'Profile GUID Missing'
      },
      
      loadLicenses(paginationIndex) {
        console.log('OrgLicenseManager loadLicenses')
        
        this.showAssignLicenseAllocation = false
        this.loading = true
        this.items = []
        
        const paginationToken = this.paginationTokens[paginationIndex - 1]
        
        var queryParams = {
          'pagination_size': this.itemsPerPage,
          'org_guid': this.organization.org_guid
        }
        
        if (paginationToken) {
          queryParams.pagination_token = paginationToken
        }
        
        this.axios.get('/entitlements/licenses', {'params': queryParams}).then((response) => {
          console.log('OrgLicenseManager loadLicenses response', response)
          
          if (response.data.licenses.length > 0) {
            this.items = response.data.licenses
            
            if (response.data.pagination_token) {
              this.$set(this.paginationTokens, paginationIndex, response.data.pagination_token)
            }
          }
          
          this.loading = false
        }).catch((error) => {
          console.log('OrgLicenseManager loadLicenses error', error)
          this.error = true
          this.errorText = this.$helpers.parseError(error)
          this.loading = false
        })
      },
      
      assignLicenses(item) {
        this.selectedLicense = item
        this.allocationLoading = true
        
        this.showAssignLicenseAllocation = true
      },
      
      hasExpired(item) {
        var timeNow = moment()
        var timeExpired = moment(item.expires)
        if (timeNow.diff(timeExpired, 'days') > 0) {
          return true
        }
        return false
      }
    }
  }
</script>