<template>
  <tr>
    <td>
      <v-checkbox v-model="thisEnabled"></v-checkbox>
    </td>
    
    <td>
      <span :class="(thisEnabled) ? '' : 'grey--text'" class="text-no-wrap">{{ $helpers.getLabel(capability.feature) }}</span>
    </td>
    
    <td>
      <v-row v-if="capability.type === 'number'" align="center">
        <v-col cols="9">
          <v-text-field
            v-if="capability.type === 'number'"
            v-model.number="thisValue"
            :disabled="!thisEnabled"
            :error-messages="valueErrors"
            type="number"
            autocomplete="off"
          ></v-text-field>
        </v-col>
        
        <v-col cols="3" class="text-right">
          <v-btn @click="setMax()" x-small outlined>Max</v-btn>
        </v-col>
      </v-row>
      
      <v-row v-else-if="capability.type === 'array'" align="center">
        <v-col cols="9">
          <v-select
            v-if="capability.type === 'array'"
            v-model="thisValue"
            :disabled="!thisEnabled"
            :error-messages="valueErrors"
            :items="capability.possible"
            multiple
          ></v-select>
        </v-col>
        
        <v-col cols="3" class="text-right">
          <v-btn @click="selectAll()" x-small outlined>All</v-btn>
        </v-col>
      </v-row>
      
      <v-row v-else-if="capability.type === 'range'">
        <v-col cols="4">
          <v-text-field
            v-model.number="thisValue.min"
            :disabled="!thisEnabled"
            :error-messages="valueErrors"
            type="number"
            autocomplete="off"
          ></v-text-field>
        </v-col>
        
        <v-col cols="5">
          <v-text-field
            v-model.number="thisValue.max"
            :disabled="!thisEnabled"
            :error="valueErrors.length > 0"
            type="number"
            autocomplete="off"
          ></v-text-field>
        </v-col>
      </v-row>
    </td>
  </tr>
</template>

<script>
  import Vue from 'vue'

  import { validationMixin } from 'vuelidate'
  import { required, between, minLength, minValue, maxValue } from 'vuelidate/lib/validators'

  export default {
    name: 'EntitlementFeature',
    mixins: [validationMixin],

    props: ['capability', 'value'],
    
    data() {
      return {
        maxValue: this.value,
        selectAllValue: this.value
      }
    },
    
    validations() {
      var type = this.capability.type

      if (type === 'number') {
        return {
          thisValue: {
            required,
            between: between(this.capability.min, this.capability.max)
          }
        }
      } else if (type === 'array') {
        return {
          thisValue: {
            required,
            minLength: minLength(1)
          }
        }
      } else if (type === 'range') {
        return {
          thisValue: {
            min: {
              required,
              minValue: minValue(this.capability.min)
            },
            max: {
              required,
              maxValue: maxValue(this.capability.max)
            }
          }
        }
      } else {
        return {
        }
      }
    },
    
    computed: {
      thisEnabled: {
        get() {
          return this.value !== undefined
        },

        set(enabled) {
          var value = this.thisValue

          if (this.capability.type === 'boolean') {
            value = enabled
          }

          this.$emit('update', this.capability.feature, enabled ? value : undefined)
        }
      },

      thisValue: {
        get() {
          var type = this.capability.type

          var value = this.value

          if (!value) {
            if (type === 'number') {
              value = this.capability.min
            } else if (type == 'array') {
              value = this.capability.defaultSelected
            } else if (type === 'boolean') {
              value = false
            } else if (type === 'range') {
              value = Vue.observable({
                min: this.capability.min,
                max: this.capability.max
              })
            }
          }

          return value
        },

        thisValueMax: {
        get() {
          var type = this.capability.type

          var value = this.value

          if (!value) {
            if (type === 'number') {
              value = this.capability.max
            }
          }

          return value
        },
      },

        set(value) {
          this.$emit('update', this.capability.feature, this.thisEnabled ? value : undefined)
        }
      },

      valueErrors() {
        var errors = []

        var type = this.capability.type

        if (this.$v.thisValue.$invalid && this.thisEnabled) {
          if (type === 'number') {
            if (!this.$v.thisValue.required) {
              errors.push("Value Required")
            }

            if (!this.$v.thisValue.between) {
              errors.push(`Value Must be Between ${this.capability.min} and ${this.capability.max}`)
            }
          }

          if (type === 'array') {
            if (!this.$v.thisValue.required) {
              errors.push("Value Required")
            }

            if (!this.$v.thisValue.minLength) {
              errors.push(`Array Must Include at least 1 Element`)
            }
          }

          if (type === 'range') {
            if (!this.$v.thisValue.min.required) {
              errors.push("Min Value Required")
            }

            if (!this.$v.thisValue.max.required) {
              errors.push("Max Value Required")
            }


            if (!this.$v.thisValue.min.minValue) {
              errors.push(`Min Value Must Be At or Above ${this.capability.min}`)
            }

            if (!this.$v.thisValue.max.maxValue) {
              errors.push(`Max Value Must Be At or Below ${this.capability.max}`)
            }
          }
        }

        return errors
      }
    },

    methods: {
      setMax() {
        this.maxValue = this.capability.max
        this.$emit('update', this.capability.feature, this.maxValue)
      },

      selectAll() {
        this.selectAllValue = this.capability.possible
        this.$emit('update', this.capability.feature, this.selectAllValue)
      }
    }
  }
</script>