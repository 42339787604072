<template>
  <div>
    <v-dialog
      v-model="showUninstallContainer"
      max-width="480px"
    >
      <videon-card heading="Uninstall Container" showClose="true" @close="showUninstallContainer = false">
        <v-card-text>
          <p class="subtitle-1 mt-3">
            Are you sure you want to uninstall '<span class="primary--text">{{
              uninstallContainerId && $containers.getContainer(uninstallContainerId).name
            }}</span>'?
          </p>
          <v-form @submit.prevent="confirmUninstallContainer">
            <div class="text-right">
              <v-btn
                class="ma-1"
                color="secondary"
                @click="showUninstallContainer = false"
                text
              >
                Cancel
              </v-btn>
              <v-btn
                class="ma-1"
                color="primary"
                @click="confirmUninstallContainer(uninstallContainerId)"
              >
                Uninstall
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </videon-card>
    </v-dialog>
    
    <v-card flat>
      <v-card-title>
        Installed Containers
        
        <v-spacer />
        
        <v-btn
          small
          outlined
          class="mr-1"
          color="secondary"
          @click="refreshContainers"
          tooltip="Reload Containers"
          :disabled="loading"
        >
          Reload
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="containerHeaders"
          :items="directoryContainers"
          hide-default-footer
          disable-pagination
          :loading="loading"
          :items-per-page="200"
        >
          <template v-slot:item.name="{ item }">
            <div class="text-truncate" style="max-width: 350px;">
              {{ $containers.getContainer(item.container_id).name || item.name }}
            </div>
          </template>
          <template v-slot:item.company="{ item }">
            <div
              v-if="containerLoaded(item.container_id)"
              class="text-truncate"
              style="max-width: 150px;"
            >
              {{ $containers.getContainer(item.container_id).companyName }}
            </div>
            <v-progress-linear
              v-else
              color="primary"
              indeterminate
              buffer-value="0"
            ></v-progress-linear>
          </template>
          <template v-slot:item.description="{ item }">
            <div
              v-if="containerLoaded(item.container_id)"
              style="max-width: 200px;"
              class="text-truncate"
            >
              {{ $containers.getContainer(item.container_id).description }}
            </div>
            <v-progress-linear
              v-else
              color="primary"
              indeterminate
              buffer-value="0"
            ></v-progress-linear>
          </template>
          <template v-slot:item.learn_more="{ item }">
            <container-info :container="$containers.getContainer(item.container_id)" />
          </template>
          <template v-slot:item.actions="{ item }">
            <div v-if="actionedContainer.container_id == item.container_id">
              <v-progress-linear
                color="black"
                indeterminate
                buffer-value="0"
              ></v-progress-linear>
            </div>
            <div v-else>
              <v-btn
                color="red"
                outlined
                small
                @click="uninstallContainer(item)"
                :disabled="!canEdit || loading || !containerLoaded(item.container_id)"
              >
                Uninstall
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    
    <v-card flat>
      <v-card-title>
        Available Containers
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="containerHeaders"
          :items="directoryPage"
          hide-default-footer
          disable-pagination
          :loading="directoryLoading"
          :items-per-page="itemsPerPage"
        >
          <template v-slot:item.name="{ item }">
            <div class="text-truncate" style="max-width: 350px;">
              {{ item.name }}
            </div>
          </template>
          <template v-slot:item.company="{ item }">
            <div
              class="text-truncate"
              style="max-width: 150px;"
            >
              {{ item.companyName }}
            </div>
          </template>
          <template v-slot:item.description="{ item }">
            <div
              class="text-truncate"
              style="max-width: 200px;"
            >
              {{ item.description }}
            </div>
          </template>
          <template v-slot:item.learn_more="{ item }">
            <container-info :container="item" />
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn
              color="green"
              outlined
              small
              @click="installContainer(item)"
              :disabled="!canEdit || loading || !!directoryContainers.find((container) => container.container_id == item.container_guid)"
            >
              Install
            </v-btn>
          </template>
        </v-data-table>
        <data-table-pagination v-model="page" :paginationTokens="paginationTokens" />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  export default {
    name: 'ContainersList',
    
    props: [
      'loading',
      'isOnline',
      'isProcessing',
      'actionedContainer',
      'containers',
      'platform',
      'canEdit'
    ],
    
    data() {
      return {
        containerHeaders: [
          { text: 'Name', align: 'start', value: 'name', width: '20%', sortable: false },
          { text: 'Made By', align: 'start', value: 'company', width: '20%', sortable: false },
          { text: 'Description', align: 'start', value: 'description', sortable: false },
          { text: '', align: 'right', value: 'learn_more', sortable: false },
          { text: '', align: 'right', value: 'actions', width: 250, sortable: false },
        ],
        
        selectedContainer: false,
        
        showUninstallContainer: false,
        uninstallContainerId: false,
        
        directoryLoading: false,
        directoryPage: [],
        
        itemsPerPage: 25,
        page: 1,
        pageCount: 0,
        
        paginationTokens: []
      }
    },
    
    mounted() {
      this.searchDirectory(0)
    },
    
    watch: {
      page() {
        this.searchDirectory(this.page - 1)
      },
      
      itemsPerPage() {
        this.page = 1
      }
    },
    
    computed: {
      directoryContainers() {
        return this.containers?.filter((container) => !!container.container_id)
      }
    },
    
    methods: {
      containerLoaded(container_id) {
        return this.$containers.getContainer(container_id).ready
      },
      
      searchDirectory(paginationIndex) {
        if (!this.directoryLoading) {
          this.directoryLoading = true
          this.directoryPage = []
          
          const paginationToken = this.paginationTokens[paginationIndex - 1]
          
          var queryParams = {
            'pagination_size': this.itemsPerPage,
            'platform': this.platform
          }
          
          if (paginationToken) {
            queryParams.pagination_token = paginationToken
          }
          
          this.axios.get('/containers', {'params': queryParams}).then((response) => {
            console.log('ContainersList searchDevices response', response)
            
            if (response.data.containers.length > 0) {
              this.directoryPage = response.data.containers.map(
                (container) => this.$containers.getContainer(container.container_id, container)
              )
              
              if (response.data.pagination_token) {
                this.$set(this.paginationTokens, paginationIndex, response.data.pagination_token)
              }
            }
            
            this.directoryLoading = false
          }).catch((error) => {
            console.log('ContainersList searchDevices error', error)
            // TODO
            this.directoryLoading = false
          })
        }
      },
      
      uninstallContainer(item) {
        console.log('ContainersList uninstallContainer', item)
        
        this.selectedContainer = item
        
        this.showUninstallContainer = true
        this.uninstallContainerId = item.container_id
      },
      
      refreshContainers() {
        this.$emit("refresh")
      },
      
      confirmUninstallContainer() {
        console.log('ContainersList confirmUninstallContainer', this.selectedContainer)
        
        this.showUninstallContainer = false
        
        this.$emit('uninstall', this.selectedContainer)
      },
      
      installContainer(item) {
        console.log('ContainersList installContainer', item)
        
        if (item.container_guid) {
          this.$emit('install', item.container_guid)
        }
      }
    }
  }
</script>