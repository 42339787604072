<template>
  <div @click="showEntitlementDetails = true" style="cursor: pointer; ">
    <v-dialog v-model="showEntitlementDetails" max-width="600px">
      <videon-card
        :heading="license.license_name || entitlementProfile.displayName()"
        showClose="true"
        @close="showEntitlementDetails = false"
      >
        <div v-if="!entitlementProfile || !platformCapabilities">
          <v-alert v-if="!entitlementProfile" outlined type="error">
            Unable to get entitlement profile
          </v-alert>
          <v-alert v-else-if="!platformCapabilities" outlined type="error">
            Unable to get capability profile
          </v-alert>
        </div>
        <v-card-text v-else>
          <v-row>
            <v-col v-if="platformCapabilities && Object.keys(entitlementProfile.properties).length > 0">
              <ul>
                <span
                  v-for="(capability, index) in platformCapabilities.capabilities"
                  :key="index"
                >
                  <li 
                    v-if="capability.feature in entitlementProfile.properties"
                    class="my-2"
                  >
                    <strong>{{ $helpers.getLabel(capability.feature) }}</strong>
                    
                    <span v-if="capability.type === 'number'">
                      : {{ getProperty(capability.feature) }}
                    </span>
                    
                    <span v-else-if="capability.type === 'array'">
                      <span>:&nbsp;</span>
                      <v-chip
                        v-for="(item, index) in getProperty(capability.feature)"
                        :key="index"
                        outlined
                        small
                        class="mr-1"
                      >
                        {{ item }}
                      </v-chip>
                    </span>
                    
                    <span v-else-if="capability.type === 'range'">
                      : [{{ getProperty(capability.feature).min }}...{{ getProperty(capability.feature).max }}]
                    </span>
                  </li>
                </span>
              </ul>
            </v-col>
            <v-col v-else>
              <v-alert outlined type="error">
                Entitlement has no properties set...
              </v-alert>
            </v-col>
          </v-row>
        </v-card-text>
      </videon-card>
    </v-dialog>
    
    <span
      v-if="license.license_name"
      class="subtitle-1" :class="(license.expired) ? 'red--text' : ''"
    >
      {{ license.license_name }}
    </span>
    <span
      v-else class="subtitle-1"
      :class="(license.expired || !entitlementProfile || entitlementProfile.error) ? 'red--text' : ''"
    >
      {{
        entitlementProfile && !entitlementProfile.error
          ? entitlementProfile.displayName()
          : 'Entitlement Not Found'
      }}
    </span>

    <v-chip
      class="ml-3"
      color="error"
      small
      pill
      v-if="license.expired"
    >
      Expired
    </v-chip>
    <v-chip
      class="ml-3"
      color="accent"
      small
      pill
      v-if="license.pending_deallocation"
    >
      Pending Deallocation
    </v-chip>
  </div>
</template>

<script>
  export default {
    name: 'LicenseLabel',
    
    props: ['license'],

    data() {
      return {
        showEntitlementDetails: false
      }
    },
    
    computed: {
      entitlementProfile() {
        return (
          this.license &&
          this.license.profile_guid &&
          this.$entitlementProfiles.getEntitlementProfile(this.license.profile_guid)
        )
      },
      
      platformCapabilities() {
        return (
          this.entitlementProfile &&
          this.entitlementProfile.profile_platform &&
          this.$capabilities.getCapabilityProfile(this.entitlementProfile.profile_platform)
        )
      }
    },
    
    methods: {
      getProperty(name) {
        return this.entitlementProfile.properties[name]
      }
    }
  }
</script>